import { ListItem } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';

const FooterListItem = withStyles((theme: Theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightMedium,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),

    '&:hover, &:active, &:focus': {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
    },
  },
}))(ListItem);

export default FooterListItem;
