import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FacebookIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <path d="M22.1914 0H3.80859C1.70871 0 0 1.70871 0 3.80859V22.1914C0 24.2913 1.70871 26 3.80859 26H11.4766V16.8086H8.42969V12.2383H11.4766V9.14062C11.4766 6.62041 13.5267 4.57031 16.0469 4.57031H20.668V9.14062H16.0469V12.2383H20.668L19.9062 16.8086H16.0469V26H22.1914C24.2913 26 26 24.2913 26 22.1914V3.80859C26 1.70871 24.2913 0 22.1914 0Z" />
    </SvgIcon>
  );
};

export default FacebookIcon;
