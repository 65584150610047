import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CirclePlusIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <circle cx="13" cy="13" r="13" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 21V5H15L15 21H11Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 15H5V11H21V15Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default CirclePlusIcon;
