import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const YoutubeIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <path d="M24.6725 6.90766C24.3917 5.86342 23.5683 5.04026 22.5243 4.75908C20.6168 4.23718 12.987 4.23718 12.987 4.23718C12.987 4.23718 5.35747 4.23718 3.45005 4.73929C2.42604 5.02018 1.58265 5.86357 1.30177 6.90766C0.799805 8.81493 0.799805 12.7704 0.799805 12.7704C0.799805 12.7704 0.799805 16.7457 1.30177 18.6331C1.58295 19.6772 2.40596 20.5003 3.4502 20.7815C5.37756 21.3036 12.9873 21.3036 12.9873 21.3036C12.9873 21.3036 20.6168 21.3036 22.5243 20.8014C23.5685 20.5204 24.3917 19.6972 24.6728 18.6532C25.1747 16.7457 25.1747 12.7905 25.1747 12.7905C25.1747 12.7905 25.1947 8.81493 24.6725 6.90766ZM10.5578 16.4245V9.1162L16.9024 12.7704L10.5578 16.4245Z" />
    </SvgIcon>
  );
};

export default YoutubeIcon;
