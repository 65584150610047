import React, { FC, PropsWithChildren } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import ErrorBoundary from '../../ErrorBoundary';
import Footer from '../Footer';
import NavBar from '../Navbar';
import theme from '../theme';

type DataProps = {
  hasNavBar?: boolean;
  hasFooter?: boolean;
};

const Layout: FC<PropsWithChildren<DataProps>> = ({
  children,
  hasNavBar,
  hasFooter,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CssBaseline />
        {hasNavBar && <NavBar />}
        <main>{children}</main>
        {hasFooter && <Footer />}
      </ErrorBoundary>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  hasNavBar: true,
  hasFooter: true,
};

export default Layout;
