import React, { FC, PropsWithChildren } from 'react';
import {
  Container,
  Link,
  List,
  ListItem,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from '../Logo';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../SvgIcon';
import SocialIconLink from '../SocialIconLink/index';
import FooterList from '../FooterList';
import FooterListItem from '../FooterListItem';

type DataProps = Record<string, unknown>;

const useStyles = makeStyles((theme: Theme) => ({
  rootFooter: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },

  rootSocialIconsList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  rootSocialIconsListItem: {
    width: 'auto',
    paddingRight: theme.spacing(2),

    '&:last-child': {
      paddingRight: 0,
    },
  },
  wlcLogo: {
    paddingTop: '20px',
    marginTop: '20px',
  },
}));

const Footer: FC<PropsWithChildren<DataProps>> = () => {
  const classes = useStyles();
  const pdfs = useStaticQuery(graphql`
    query vopPdfDocs {
      vopPdf: file(
        name: { eq: "BeRider_-_smlouva_o_pronajmu_kol" }
        ext: { eq: ".pdf" }
      ) {
        name
        extension
        publicURL
      }
    }
  `);

  return (
    <footer className={classes.rootFooter}>
      <Container>
        <Grid container spacing={3}>
          <Grid className={classes.wlcLogo} item xs={12} sm={6} md={3}>
            <Logo type="wlc" isLink />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Logo type="white" isLink />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FooterList disablePadding>
              <FooterListItem component="li" dense disableGutters>
                <Link
                  target="_blank"
                  color="inherit"
                  href={pdfs.vopPdf.publicURL}
                  rel="noopener noreferrer"
                >
                  Náhled smlouvy o pronájmu
                </Link>
              </FooterListItem>
            </FooterList>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography component="div" variant="h6">
              Zavolejte nám *
            </Typography>

            <Typography component="div" variant="body2" gutterBottom>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  Všední dny
                </Grid>
                <Grid item xs={6}>
                  7:00 - 21:00
                </Grid>
                <Grid item xs={6}>
                  Víkendy a svátky
                </Grid>
                <Grid item xs={6}>
                  9:00 - 21:00
                </Grid>
              </Grid>
            </Typography>

            <Typography component="div" variant="body2" gutterBottom>
              * Možnost doručení kol i v jiné časy na základě domluvy.
            </Typography>

            <Typography
              component="a"
              color="primary"
              variant="h6"
              gutterBottom
              href="tel:+420704862068"
            >
              +420 704 862 068
            </Typography>

            <Typography component="div" variant="h6">
              Napište nám
            </Typography>

            <Typography
              component="a"
              color="primary"
              variant="h6"
              href="mailto: bikes@be-rider.com"
            >
              bikes@be-rider.com
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <List className={classes.rootSocialIconsList} disablePadding>
              <ListItem
                className={classes.rootSocialIconsListItem}
                disableGutters
              >
                <SocialIconLink
                  target="_blank"
                  href="https://www.facebook.com/beridercom/"
                  rel="noopener noreferrer"
                >
                  <FacebookIcon />
                  <Typography variant="srOnly">Facebook</Typography>
                </SocialIconLink>
              </ListItem>

              <ListItem
                disableGutters
                className={classes.rootSocialIconsListItem}
              >
                <SocialIconLink
                  target="_blank"
                  href="https://www.instagram.com/beridercom/"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                  <Typography variant="srOnly">Instagram</Typography>
                </SocialIconLink>
              </ListItem>

              <ListItem
                disableGutters
                className={classes.rootSocialIconsListItem}
              >
                <SocialIconLink
                  target="_blank"
                  href="https://www.youtube.com/channel/UCTraRH908PolsEgH2uNgETA/"
                  rel="noopener noreferrer"
                >
                  <YoutubeIcon />
                  <Typography variant="srOnly">Youtube</Typography>
                </SocialIconLink>
              </ListItem>

              <ListItem
                disableGutters
                className={classes.rootSocialIconsListItem}
              >
                <SocialIconLink
                  target="_blank"
                  href="https://twitter.com/beridercom/"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon />
                  <Typography variant="srOnly">Twitter</Typography>
                </SocialIconLink>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
