import {
  AppBar,
  AppBarProps,
  Container,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import Logo from '../Logo';
import toolbarHeightStyles from './toolbarHeightStyles';

type DataProps = AppBarProps;

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: toolbarHeightStyles(theme),
  scrolledToolbar: {
    height: 60,
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
  },
  buttonOffer: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 6,
      paddingLeft: 6,
      fontSize: theme.typography.pxToRem(12),
    },
  },
  tabsRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: 'auto',
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  navLink: {
    display: 'inline-block',
    opacity: 1,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(13.5),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.4,
    paddingTop: theme.spacing(1.7),
    paddingBottom: theme.spacing(1.7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 'auto',
    textTransform: 'inherit',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),

    // '&::after': {
    //   content: "''",
    //   display: 'block',
    //   width: '15%',
    //   paddingTop: theme.spacing(1),
    //   borderBottom: '1px solid black',
    //   transition: theme.transitions.create(['all'], {
    //     easing: theme.transitions.easing.easeInOut,
    //     duration: theme.transitions.duration.shorter,
    //   }),
    // },

    '&:hover': {
      color: theme.palette.primary.main,

      // '&::after': {
      //   width: '100%',
      //   borderColor: theme.palette.primary.main,
      // },
    },
  },
}));

const NavBar: FC<DataProps> = ({ color }) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    threshold: 80,
  });
  const triggerElevation = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar
        color={triggerElevation ? 'inherit' : color}
        elevation={triggerElevation ? 2 : 0}
        classes={{ root: classes.appBar }}
      >
        <Container disableGutters>
          <Toolbar
            className={
              triggerElevation ? classes.scrolledToolbar : classes.toolbar
            }
          >
            <Logo isLink />

            <div className="header-main-nav">
              <a href="https://be-rider.com" className="header-main-link">
                <div className="header-main-link-text">Skútry</div>
              </a>
              <div className="header-main-link active">
                <div className="header-main-link-text">Kola</div>
              </div>
              <a
                href="https://be-rider.com/eshop"
                className="header-main-link last"
              >
                <div className="header-main-link-text">e-shop</div>
              </a>
            </div>

            <Tabs
              value={0}
              aria-label="Main Navigation"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                classes={{ root: classes.navLink }}
                component={Link}
                to={'/'}
                label="CZ"
                disableFocusRipple
                disableRipple
              />
            </Tabs>
          </Toolbar>
        </Container>
      </AppBar>
    </Slide>
  );
};

NavBar.defaultProps = {
  color: 'inherit',
  elevation: 0,
};

export default NavBar;
