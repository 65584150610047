import {
  createMuiTheme,
  Theme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

let theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#77DF00',
      dark: '#65BD00',
      contrastText: 'black',
    },
    secondary: {
      main: '#000',
      contrastText: 'white',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeightBold: 900,
    fontWeightMedium: 700,
  },
});

theme.palette.background.default = theme.palette.common.white;
theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '3.5rem',
  fontWeight: theme.typography.fontWeightBold,
};
theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '3rem',
  fontWeight: theme.typography.fontWeightBold,
};
theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '2.5rem',
  fontWeight: theme.typography.fontWeightMedium,
};
theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '2rem',
  fontWeight: theme.typography.fontWeightMedium,
};
theme.typography.h5 = {
  ...theme.typography.h5,
  fontSize: '1.6rem',
  fontWeight: theme.typography.fontWeightMedium,
};
theme.typography.h6 = {
  ...theme.typography.h6,
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightMedium,
};

theme.overrides = {
  MuiButton: {
    root: {
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'none',
      borderRadius: 0,
    },
  },
  MuiCard: {
    root: {
      borderRadius: 0,
    },
  },
};

theme = responsiveFontSizes(theme);

export default theme;
