import { Link } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';

const SocialIconLink = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),

    '&:hover, &:active, &:focus': {
      color: theme.palette.primary.light,
    },
  },
}))(Link);

export default SocialIconLink;
