import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TwitterIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <path d="M9.69977 23.6663C8.57545 23.6663 7.4182 23.5752 6.254 23.3927C4.20352 23.071 2.12784 21.9984 1.54049 21.6773L0 20.835L1.66784 20.2865C3.491 19.6871 4.60005 19.3151 5.97292 18.7328C4.59826 18.067 3.5388 16.8695 3.02881 15.3215L2.64081 14.1436L2.95899 14.1922C2.65728 13.8881 2.41904 13.5795 2.23437 13.2988C1.57758 12.3012 1.22985 11.0823 1.30384 10.0377L1.37684 9.00957L1.99236 9.24801C1.7327 8.75765 1.54505 8.23397 1.43476 7.6851C1.16598 6.34654 1.39092 4.92448 2.06834 3.68093L2.60451 2.69666L3.3216 3.55795C5.58949 6.28227 8.46199 7.89834 11.8713 8.37144C11.732 7.41156 11.8363 6.4852 12.1817 5.65485C12.5838 4.68803 13.2991 3.86819 14.2496 3.28381C15.3053 2.63496 16.5856 2.32056 17.8545 2.39851C19.2008 2.48123 20.4231 2.98567 21.3939 3.85827C21.868 3.73509 22.2173 3.60357 22.6894 3.42584C22.9735 3.31892 23.2956 3.19752 23.6987 3.05867L25.184 2.54689L24.2154 5.31327C24.2793 5.30791 24.3451 5.30335 24.414 5.30037L26.0001 5.22857L25.0626 6.5098C25.0089 6.58319 24.9952 6.60402 24.9761 6.63278C24.9006 6.74664 24.8065 6.88827 23.5198 8.6065C23.1976 9.03675 23.0367 9.59713 23.0667 10.1847C23.1808 12.4175 22.9068 14.4376 22.252 16.1887C21.6327 17.8455 20.673 19.2669 19.3999 20.4137C17.8245 21.8324 15.8157 22.8038 13.429 23.3005C12.2583 23.5441 11.001 23.6663 9.69977 23.6663Z" />
    </SvgIcon>
  );
};

export default TwitterIcon;
