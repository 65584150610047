import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const DEFAULT_IMAGE = 'default';
const BLACK_IMAGE = 'black';
const GREEN_IMAGE = 'green';
const WHITE_IMAGE = 'white';
const WLC_IMAGE = 'wlc';

interface Props {
  isLink?: boolean;
  type?: 'default' | 'black' | 'green' | 'white' | 'wlc';
}

const Logo: FC<Props> = ({ isLink, type }) => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      blackLogoImage: file(
        relativePath: { eq: "logos/berider-logo-black.png" }
      ) {
        childImageSharp {
          fixed(height: 40, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      greenLogoImage: file(
        relativePath: { eq: "logos/berider-logo-green.png" }
      ) {
        childImageSharp {
          fixed(height: 40, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      whiteLogoImage: file(
        relativePath: { eq: "logos/berider-logo-white.png" }
      ) {
        childImageSharp {
          fixed(height: 40, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      wlcLogoImage: file(
        relativePath: { eq: "logos/wlc_cz_logo_privazi_skoda.png" }
      ) {
        childImageSharp {
          fixed(height: 46, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  let imageSrc;
  let altTxt = 'Berider Logo';
  let linkPath = '/';
  switch (type) {
    case BLACK_IMAGE:
      imageSrc = data.blackLogoImage.childImageSharp.fixed;
      break;
    case GREEN_IMAGE:
      imageSrc = data.greenLogoImage.childImageSharp.fixed;
      break;
    case WHITE_IMAGE:
      imageSrc = data.whiteLogoImage.childImageSharp.fixed;
      break;
    case WLC_IMAGE:
      imageSrc = data.wlcLogoImage.childImageSharp.fixed;
      altTxt = 'WLC logo';
      linkPath = 'https://www.welovecycling.com/cs/';
      break;
    default:
      imageSrc = data.blackLogoImage.childImageSharp.fixed;
  }

  if (isLink && type === WLC_IMAGE) {
    return (
      <Link target="_blank" rel="noopener noreferrer" to={linkPath}>
        <Img
          fixed={imageSrc}
          alt={altTxt}
          imgStyle={{ objectFit: 'contain' }}
        />
      </Link>
    );
  } else if (isLink) {
    return (
      <Link to={linkPath}>
        <Img
          fixed={imageSrc}
          alt={altTxt}
          imgStyle={{ objectFit: 'contain' }}
        />
      </Link>
    );
  }

  return (
    <Img fixed={imageSrc} alt={altTxt} imgStyle={{ objectFit: 'contain' }} />
  );
};

Logo.defaultProps = {
  isLink: false,
  type: DEFAULT_IMAGE,
};

export default Logo;
