import { Theme } from '@material-ui/core/styles';

function toolbarHeightStyles(
  theme: Theme,
): {
  [p: string]: number | string | { height: 60 };
  transition: string;
  height: 60;
} {
  return {
    height: 60,
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      height: 60,
    },
  };
}

export default toolbarHeightStyles;
