import * as Sentry from '@sentry/browser';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props: Readonly<{ hasError: boolean }>) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    this.setState({ hasError: true });
    Sentry.configureScope(
      (scope: { setExtra: (arg0: string, arg1: unknown) => void }) => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      },
    );
    Sentry.captureException(error);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    // if (this.state.hasError) {
    //   // render fallback UI
    //   return <h1>Something went wrong!</h1>;
    // } else {
    // when there's not an error, render children untouched
    return this.props.children;
    // }
  }
}
